import {useContext} from "react";
import {AppContext} from "../../context/appContext";
import {useTranslation} from "react-i18next";
import {hideCard} from "../../helpers/common";

export function ShowOrderData() {
    const {t, i18n} = useTranslation();
    const appContext = useContext(AppContext);


    return (<div className={'info'}>
        <div className='d-flex align-items-baseline'>
            <div className=' flex-grow-1' style={{flex: 1}}>{t("Merchant")}:</div>
            <div className='flex-grow-1 fw-bold' style={{flex: 1}}>{appContext.transaction.site}</div>
        </div>
        <div className='d-flex align-items-baseline'>
            <div className=' flex-grow-1' style={{flex: 1}}>{t("Payment sum")}:</div>
            <div className='flex-grow-1 fw-bold'
                 style={{flex: 1}}>{appContext.transaction.sum}<span>{appContext.transaction.symbol}</span></div>
        </div>
        {(appContext.card.number === null) ?
            null
            :
            <div className='d-flex align-items-baseline'>
                <div className='flex-grow-1' style={{flex: 1}}>{t("Card number")}:</div>
                <div className='flex-grow-1 fw-bold' style={{flex: 1}}>{hideCard(appContext.card.number)}</div>
            </div>
        }

    </div>);
}
