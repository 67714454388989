import React, {useEffect, useRef, useState, useCallback} from 'react';
import {useNavigate} from "react-router";
import {ButyCard} from "../../components/Forms/ButyCard";
import ButyCardForm from "../../components/Forms/ButyCardForm";

const initialState = {
    cardNumber: '···· **** **** ····',
    cardHolder: 'FULL NAME',
    cardExpires: '',
    cardCvv: '',
    isCardFlipped: false
};

export function BindCard() {
    const navigate = useNavigate();
// navigate("/remote");


    const [state, setState] = useState(initialState);
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

    const updateStateValues = useCallback(
        (keyName, value) => {
            setState({
                ...state,
                [keyName]: value || initialState[keyName]
            });
        },
        [state]
    );

    // References for the Form Inputs used to focus corresponding inputs.
    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    });

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);


    useEffect(() => {
        // if (localStorage.getItem('hash') !== null) {
        //     navigate('remote');
        // }
    }, []);

    return (
        <div className='d-flex flex-column w-100'>
            {/*<BindCardForm/>*/}
            <ButyCardForm
                cardExpires={state.cardExpires}
                onUpdateState={updateStateValues}
                cardNumberRef={formFieldsRefObj.cardNumber}
                cardHolderRef={formFieldsRefObj.cardHolder}
                cardCvvRef={formFieldsRefObj.cardCvv}
                cardDateRef={formFieldsRefObj.cardDate}
                onCardInputFocus={onCardFormInputFocus}
                onCardInputBlur={onCardInputBlur}
            >
                <ButyCard
                    cardNumber={state.cardNumber}
                    cardHolder={state.cardHolder}
                    cardMonth={state.cardExpires.split('/')[0] ?? ''}
                    cardYear={state.cardExpires.split('/')[1] ?? ''}
                    cardCvv={state.cardCvv}
                    isCardFlipped={state.isCardFlipped}
                    currentFocusedElm={currentFocusedElm}
                    onCardElementClick={focusFormFieldByKey}
                    cardNumberRef={cardElementsRef.cardNumber}
                    cardHolderRef={cardElementsRef.cardHolder}
                    cardDateRef={cardElementsRef.cardDate}
                    cardCvvRef={cardElementsRef.cardCvv}
                />
            </ButyCardForm>
        </div>

    );
}
