import React from "react";
import {useTranslation} from "react-i18next";

export function TimerBankLoader() {

    const {t, i18n} = useTranslation();
    const [counter, setCounter] = React.useState(10);

    // Third Attempts
    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const counterSetter = (counter) => {
        return counter < 10 ? `0${counter}` : counter;
    };

    return <>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 position-relative'>
            <img className='position-absolute top-0 w-75 pt-2' src="/images/3ds-loader.svg" alt="3D Secure"/>

            {counter !== 0 ?
                <div className='counter'>
                    00:{counterSetter(counter)}
                </div>
                :
                <div className="spinner-border text-primary" role="status"></div>}


            <div className='position-absolute bottom-0 p-2'>
                <p className="text-center">
                    {t("wait-60-sec")}
                </p>
                <p className="text-center">
                    {t('dont-close')}
                </p>
            </div>
        </div>
    </>
}
