import React from "react";
import {Loader} from "../../../Loaders/Loader";
import {ShowOrderData} from "../../../ShowTransactionData";
import {useTranslation} from "react-i18next";

export function SendPushBankForm() {
    const [t, i18n] = useTranslation();

    return (
        <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
            <div className='mb-3'>
                {/*<img src="/images/forms/sms.svg" alt="Image"/>*/}
                <img className='w-100' src="/images/3d-secure-loader.png" alt="Image"/>
            </div>

            <div className='p-2 w-100'>
                <div className={"w-100"}>
                    <ShowOrderData/>
                </div>
                <p className='text-center p-3 mb-0'>
                    {t("Confirm push")}.
                </p>
                <div className='m-3'>
                    <Loader></Loader>
                </div>
            </div>
        </div>
    );
}
