import React from 'react';
import ReactDOM from 'react-dom';
import {App} from "./components/App";
import {i18n} from "./helpers/i18n";
import {BrowserRouter} from "react-router-dom";

window.React = React;

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    document.getElementById('banks-handler')
);
