import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Card number": "Card number",
            "Expiration Date": "Expiration Date",
            "CVV/CVC": "CVV/CVC",
            "CVV": "CVV",
            "Expires": "Expires",
            "Continue": "Continue",
            "Enter sms": "Enter the code from SMS to confirm the payment",
            "CODE": "CODE",
            "Send": "Send",
            "Confirm push": "Confirmation is required via a push notification on your deivce",
            "wait-60-sec": "It may take up to 60 seconds for the bank to process your request",
            "dont-close": "Please don't close this window",
            "MM": "MM",
            "YY": "YY",
            //next-part
            "Billing details": "Billing details",
            "First name": "First name",
            "Last name": "Last name",
            "Email": "Email",
            "Phone number": "Phone number",
            "Country": "Country",
            "State": "State",
            "City": "City",
            "Postal code": "Postal code",
            "Address": "Address",
            "Required": "Required",
            "Must be": "Must be",
            "characters or less": "characters or less",
            "characters or more": "characters or more",
            "Invalid email address": "Invalid email address",
            "Invalid phone number": "Invalid phone number",
            "Invalid post code": "Invalid post code",
            "Must be a number": "Must be a number",
            'Return to shop': 'Return to shop',
            'Payment was declined': 'Payment was declined',
            'Payment completed successfully': 'Payment completed successfully',
            'Please check the correctness of the data after entering': 'Please check the correctness of the data after entering',
            'Merchant': 'Merchant',
            'Payment sum': 'Payment sum',
            "Enter push": "Enter the code from push notification to confirm the payment",
            'Transaction expire': 'Transaction expire',
            "Amount to pay": "Amount to pay",
            "Order": "Order",
            "Description": "Description",
        }
    },
    de: {
        translation: {
            "Card number": "Kartennummer",
            "Expiration Date": "Verfallsdatum",
            "CVV/CVC": "CVV/CVC",
            "CVV": "CVV",
            "Expires": "Läuft ab",
            "Continue": "Fortsetzen",
            "Enter sms": "Geben Sie den Code aus der SMS ein, um die Zahlung zu bestätigen",
            "CODE": "CODE",
            "Send": "Senden",
            "Confirm push": "Die Bestätigung ist über eine Push-Benachrichtigung auf Ihrem Telefon erforderlich",
            "wait-60-sec": "Es kann bis zu 60 Sekunden dauern, bis die Bank Ihre Anfrage bearbeitet",
            "dont-close": "Bitte schließen Sie dieses Fenster nicht",
            "MM": "MM",
            "YY": "JJ",
            //next
            "Billing details": "Rechnungsdetails",
            "First name": "Vorname",
            "Last name": "Nachname",
            "Email": "Email",
            "Phone number": "Telefonnummer",
            "Country": "Land",
            "State": "Bundesland",
            "City": "Stadt",
            "Postal code": "Postleitzahl",
            "Address": "Adresse",
            "Required": "Erforderlich",
            "Must be": "Darf höchstens",
            "characters or less": "Zeichen lang sein",
            "characters or more": "Zeichen lang sein",
            "Invalid email address": "Ungültige E-Mail-Adresse",
            "Invalid phone number": "Ungültige Telefonnummer",
            "Invalid post code": "Ungültige Postleitzahl",
            "Must be a number": "Muss eine Nummer sein",
            'Return to shop': 'Zurück zum Shop',
            'Payment was declined': 'Die Zahlung wurde abgelehnt',
            'Payment completed successfully': 'Zahlung erfolgreich abgeschlossen',
            'Please check the correctness of the data after entering': 'Bitte überprüfen Sie nach der Eingabe die Richtigkeit der Daten',
            'Merchant': 'Händlerin',
            'Payment sum': 'Zahlungssumme',
            'Enter push': 'Geben Sie den Code aus der Push-Benachrichtigung ein, um die Zahlung zu bestätigen',
            'Transaction expire': 'Transaktion verfällt',
            "Amount to pay": "Zu zahlender Betrag",
            "Order": "Befehl",
            "Description": "Beschreibung",
        }
    },

};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
