export function findGetParameter(parameterName) {
    let result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

export function hideCard(number) {
    return number.slice(0, 4) + ' ···· ···· ' + number.slice(number.length - 4);
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    let rawData = parts.pop().split(';').shift();
    return decodeURIComponent(rawData);
  }

}


export async function sendLogs(hash, type = ''){
    fetch(`/api/form-events/${hash}/log`, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
        }, redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify({ payload: {}, type: type  }) 
    }).then((response) => {
        //log sent
    }).then((response) => {
    }).finally((response) => {
    });
}