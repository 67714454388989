import {BillingForm} from "../../components/Forms/BillingForm";

export function BillingDetails() {


    return (
        <div className='d-flex flex-column w-100'>
            <BillingForm/>
        </div>
    );
}
