import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../context/appContext";

export function ErrorFrame({message = 'Payment was declined', title = null}) {
    const [t, i18n] = useTranslation();
    const appContext = useContext(AppContext);

    return (<div className="card-form card-form__inner p-0 d-flex m-auto" style={{
        minHeight: "400px",
    }}>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
            <div className='mb-3 mt-3'>
                <img className='w-100' width='100' height="100" src="/images/error.svg" alt="Image"/>
            </div>


            {title !== null ? <div className='p-1 w-100'><h4 className='fw-bold text-center'>{t(title)}</h4></div> : null}
            <div className='p-1 w-100'>
                <p className='text-center p-3 mb-0'>
                    {t(message)}
                </p>

                <div className='px-4'>
                    <a className='card-form__button text-decoration-none d-flex flex-center'
                       href={appContext.transaction.backUrl}>{t("Return to shop")}</a>
                </div>
            </div>
        </div>
    </div>);
}
