import React from "react";
import {Loader} from "../../../Loaders/Loader";

export function SendCustomTextForm({text}) {
    return (<div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
        <div className='mb-3 d-flex justify-content-center align-items-center w-100'>
            <img height={70} width={70} src="/images/personal-information.svg" alt="Image"/>
        </div>
        <p className='text-center p-3 mb-5'>{text}</p>
    </div>);
}
