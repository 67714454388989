import React from "react";

export const initTransaction = {
    sum: 0,
    symbol: '$',
    valid: false,
    site: null,
    metadata: null,
};

export const initBilling = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    country: null,
    state: null,
    city: null,
    zip: null,
    address1: null,
    address2: null,
};

export const AppContext = React.createContext({
    transaction: initTransaction,
    setTransaction: () => {
    },
    identHash: '',
    setIdentHash: () => {
    },
    card: {
        number: null,
        card: null,
        cvv: null,
    },
    setCard: () => {
    },
    billing: initBilling,
    setBilling: () => {
    },
});

