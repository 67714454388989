import React, {useContext, useEffect, useState} from 'react'
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {AppContext, initBilling, initTransaction} from "../context/appContext";
import {RemoteHandling} from "../frames/RemoteHandling";
import {BindCard} from "../frames/BindCard";
import {findGetParameter, getCookie} from "../helpers/common";
import {useTranslation} from "react-i18next";
import {BillingDetails} from "../frames/BillingDetails";
import {TimerBankLoader} from "./Loaders/TimerBankLoader";
import {SuccessFrame} from "./RemoteFrames/SuccessFrame";
import {ErrorFrame} from "./RemoteFrames/ErrorFrame";
import { MistakeFrame } from './RemoteFrames/MistakeFrame';
import { LimitFrame } from './RemoteFrames/LimitFrame';
import { InsufficientFundsFrame } from './RemoteFrames/InsufficientFundsFrame';

export function App() {
    const appContext = useContext(AppContext);
    const [transaction, setTransaction] = useState(initTransaction);
    const [identHash, setIdentHash] = useState('');
    const [billing, setBilling] = useState(initBilling);
    const [card, setCard] = useState({
        number: null, expire: null, cvv: null,
    });
    const nav = useNavigate();

    const {t, i18n} = useTranslation();

    useEffect(() => {
        let language = (findGetParameter('lang')) ?? 'en';
        i18n.changeLanguage(language);
        try {
            let identHash = getCookie('hash_id');
            setIdentHash(identHash);
            // let decodedData = JSON.parse(window.atob(findGetParameter('transaction')));
            let decodedData = JSON.parse(window.atob(getCookie('transaction')));
            console.log(decodedData);
            if (decodedData.site === undefined) {
                console.log('Site undefined!');
            }
            let transaction = {
                sum: decodedData.sum,
                symbol: decodedData.symbol ?? null,
                orderId: decodedData.orderId ?? null,
                name: decodedData.name ?? null,
                email: decodedData.email ?? null,
                phone: decodedData.phone ?? null,
                valid: true,
                metadata: decodedData.metadata ?? null,
                site: decodedData.site ?? null,
                successUrl: decodedData.success_url ?? null,
                backUrl: decodedData.back_url ?? null,
            };
            setTransaction(transaction);
            localStorage.setItem("transaction", JSON.stringify(transaction));

        } catch (e) {
            setTransaction({
                ...initTransaction, valid: false,
            });
            nav('/remote');
        }
    }, []);

    return <>
        <AppContext.Provider value={{
            card: card,
            setCard: setCard,
            transaction: transaction,
            setTransaction: setTransaction,
            identHash: identHash,
            setIdentHash: setIdentHash,
            billing: billing,
            setBilling: setBilling,
        }}>
            {/*<div className='lang-btns'*/}
            {/*     style={{borderRadius: "10px", backgroundColor: 'white', right: '10px', top: "10px"}}>*/}
            {/*    <div className={i18n.language === 'de' ? "p-2 lang-btn active" : "p-2 lang-btn"} onClick={() => {*/}
            {/*        i18n.changeLanguage("de");*/}
            {/*    }}>DE*/}
            {/*    </div>*/}
            {/*    <div className={i18n.language === 'en' ? "p-2 lang-btn active" : "p-2 lang-btn"} onClick={() => {*/}
            {/*        i18n.changeLanguage("en");*/}
            {/*    }}>EN*/}
            {/*    </div>*/}
            {/*</div>*/}

            <Routes>
                <Route path="/" element={<BindCard/>}/>
                <Route path="/billing" element={<BillingDetails/>}/>
                <Route path="/remote" element={<RemoteHandling/>}/>
                <Route path="/debug" element={<InsufficientFundsFrame/>}/>

                <Route
                    path="*"
                    element={<main style={{padding: "1rem"}}>
                        <p>There's nothing here!</p>
                    </main>}
                />
            </Routes>

        </AppContext.Provider>
    </>
}
