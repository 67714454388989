import React from "react";

export function Loader() {

    const [counter, setCounter] = React.useState(10);

    // Third Attempts
    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const counterSetter = (counter) => {
        return counter < 10 ? `0${counter}` : counter;
    };

    return <>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 position-relative'>
            <div className="spinner-border text-primary" role="status"></div>
        </div>
    </>
}
