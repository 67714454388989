import React, {useContext, useState} from "react";
import {sendPayload} from "../../../../helpers/http";
import {FrameContext} from "../../../../context/frameContext";
import {AppContext} from "../../../../context/appContext";

export function SendCustomTextInputForm({text}) {
    const frameContext = useContext(FrameContext);
    const appContext = useContext(AppContext);

    const [value, setValue] = useState(null);

    const submit = (e) => {
        e.preventDefault();
        sendPayload(
            {
                value: value,
            },
            appContext.identHash,
            () => {
                frameContext.changeToLoader()       ;
            },
            () => {
            },
        );
    };

    return (
        <form onSubmit={submit}
              className='d-flex flex-column justify-content-center align-items-center w-100 h-100 '>

            <div className='mb-3 d-flex justify-content-center align-items-center w-100'>
                <img height={70} width={70} src="/images/personal-information.svg" alt="Image"/>
            </div>

            <p className='text-center p-3 mb-5'>{text}</p>
            <div className='w-100 mb-3 p-3'>
                <input required={true} onChange={(e) => {
                    setValue(e.target.value);
                }} className='form-control text-center' placeholder='...'
                       type="text"/>
            </div>
            <button className='bind-card-btn border-0'>Send</button>
        </form>
    );
}
